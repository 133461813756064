import axios from "axios";
import { GET_ERRORS, SET_CURRENT_USER } from "./types";
import { URL_API } from "./urls";
import setAuthToken from "../helpers/setAuthToken";
import jwt_decode from "jwt-decode";
import { refreshToken } from "../helpers/refresh";

export const registerUser = (user, history) => dispatch => {
  axios
    .post(URL_API + "/api/users/register", user)
    .then(res => history.push("/login"))
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

export const loginUser = (user, after) => dispatch => {
  localStorage.removeItem("jwtToken");
  localStorage.clear();
  axios
    .post(URL_API + "auth", user, { headers: { Accept: "application/json" } })
    .then(res => {
      const { data } = res.data;
      localStorage.setItem("jwtToken", data);
      setAuthToken(data);
      const decoded = jwt_decode(data);
      refreshToken();
      dispatch(setCurrentUser(decoded));
      if (after) {
        after();
      }
    })
    .catch(err => {
      let erro = { message: "Não foi possível fazer o login" };
      if (err.response) {
        erro = err.response.data;
      }
      dispatch({
        type: GET_ERRORS,
        payload: erro
      });
    });
};

export const resetPassword = (email, after) => dispatch => {
  axios
    .post(
      URL_API + "reset-password",
      { email },
      { headers: { Accept: "application/json" } }
    )
    .then(after)
    .catch(err => {
      let erro = { message: "Não foi possível enviar sua nova senha" };
      if (err.response) {
        erro = err.response.data;
      }
      dispatch({
        type: GET_ERRORS,
        payload: erro
      });
    });
};

export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

export const logoutUser = history => dispatch => {
  localStorage.removeItem("jwtToken");
  localStorage.clear();
  setAuthToken(false);
  dispatch(setCurrentUser({}));
  /*if(history)
        history.push('/login');
    else */
  window.location.href = "/login";
};
